<template>
  <div>
    <div class="comments">
      <Alert
        :alert-visible="showAlert"
        :alert-data="alertData"
        @closeModal="isAlertClosed"
      />
      <v-btn
        class="favorite-btn"
        icon
        size="small"
        :disabled="!isLogin"
        :class="{
          'course-details': inCourseDetails,
          'favorite-btn--clicked': checked
        }"
        @click="favorite()"
      >
        <v-icon dark> mdi-heart </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import Alert from "~/components/shared/Alert.vue";
import { useAuthStore } from '~/stores/AuthStore';
import { useCourseStore } from "~/stores/CourseStore";

const courseStore = useCourseStore();
const authStore = useAuthStore();

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  type: {
    type: String,
    default: "Course"
  },
  inCourseDetails: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["onFavorite"]);

const awaitFavorite = ref(null);
const checked = ref(false);
const showAlert = ref(false);
const alertData = ref({});

const isLogin = computed(() => authStore.auth.isLoggedIn);

const setFavoriteDetails = () => {
  checked.value = false;
  checked.value = !!props.item.favorite;
};

const favorite = () => {
  checked.value = !checked.value;
  clearTimeout(awaitFavorite.value);
  awaitFavorite.value = setTimeout(() => {
    if (checked.value) {
      addToFavorite();
    } else {
      deleteFromFavorite();
    }
  }, 500);
};

const addToFavorite = async () => {
  const formData = new FormData();
  formData.append("favoriteable_id", props.item.id);
  formData.append(
    "favoriteable_type",
    props.item.model_type || props.item.type
  );
  await courseStore.addFavorite(formData).then((resData) => {
    setAlertData(resData);
    if (resData.success) {
      onFavorite();
    }
  });
};

const deleteFromFavorite = async () => {
  const ids =
    props.type === "Favorites" ? props.item.id : props.item.favorite.id;
  if (ids) {
    await courseStore.deleteFavorite(ids).then((resData) => {
      setAlertData(resData);
      if (resData.success) {
        onFavorite();
      }
    });
  }
};

const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};

const isAlertClosed = (payload) => {
  if (payload.value) {
    showAlert.value = false;
  }
};

const onFavorite = () => {
  emit("onFavorite", { value: true });
};

watch(() => props.item, setFavoriteDetails);

onMounted(() => {
  setFavoriteDetails();
});
</script>

<style lang="scss" scoped>
.favorite-btn {
  border-radius: 18px;
  // height: 89%;
  // width: 48px;
  border-radius: 50%;
  background-color: #d8d8d8 !important;
  color: #949494 !important;
  font-size: 18px;
  padding-top: 3px;
  // padding: 15px 0px;
}
.favorite-btn.course-details {
  background-color: #fff !important;
}
.favorite-btn--clicked {
  background-color: #e5717b !important;
  color: #fff !important;
}
.favorite-btn--clicked.course-details {
  background-color: #e5717b !important;
  color: #fff !important;
}

</style>
